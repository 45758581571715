import React, {useEffect, useState} from "react";
import {SocialMeta} from "../../components/SocialMeta";
import {useHeaderContext} from "../../layout/context/HeaderContext";
import {Accordion} from "react-bootstrap";
import TopBanner from "../home/components/TopBanner";

interface FAQ {
    question: string;
    answer: string;
}

const faqs: FAQ[] = [
    {
        question: "What services does Olive Branch Dubai offer?",
        answer: "At Olive Branch Dubai, we offer a range of real estate services, including helping buyers find their ideal properties, assisting sellers in quickly selling their properties at the best price, and providing rental services for residential and commercial properties. We also offer expert investment advice and up-to-date market information. Throughout the entire property transaction process, we provide personalized guidance and support, ensuring our clients receive the highest standard of service."
    },
    {
        question: "How can I contact Olive Branch Dubai?",
        answer: "You can reach us via phone at <a href='tel:+534171755971'>+5341 717 55 971</a> or email at <a href='mailto:info@olivebranchdubai.com'>info@olivebranchdubai.com</a>."
    },
    {
        question: "Do you offer after-sales services?",
        answer: "Yes, at Olive Branch Properties, we offer comprehensive after-sales services. This includes assistance with coveted services like the Golden Visa application and the complex process of opening a bank account. Our commitment extends beyond real estate, ensuring a seamless, hassle-free journey for our clients who seek not just a property, but a complete experience in Dubai."
    },
    {
        question: "How can I list my property with Olive Branch Dubai?",
        answer: "You can list your property by contacting us through our website, phone, or email and our team will guide you through the listing process. Whether you're selling a residential or investment property, we use our extensive market knowledge to help you effectively market your property and attract potential buyers."
    },
    {
        question: "What types of properties do you deal with?",
        answer: "At Olive Branch Properties, we specialize in a diverse range of properties across Dubai, including luxurious apartments, modern office spaces, and prime retail locations in prominent shopping destinations. We offer off-plan, resale, and commercial properties with tailored solutions to match your preferences and budget."
    },
    {
        question: "Are there any fees for your services?",
        answer: "Our fees vary depending on the service required. Contact us via phone or email for detailed information on our fee structure."
    }
];

const Faqs: React.FC = () => {
    const {setVariant, setLogoColor} = useHeaderContext();
    const [activeKey, setActiveKey] = useState<string[]>([]);

    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark");
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);

    const handleExpandAll = () => {
        const allKeys = faqs.map((_, index) => `faqs-${index}`);
        setActiveKey(allKeys);
    };

    const handleCollapseAll = () => {
        setActiveKey([]);
    };

    const handleToggle = (key: string) => {
        if (activeKey.includes(key)) {
            setActiveKey(activeKey.filter(k => k !== key));
        } else {
            setActiveKey([...activeKey, key]);
        }
    };

    return (
        <>
            <SocialMeta
                title={'FAQs'}
                description={'Have questions or need assistance? Reach out to Olive Branch through our Contact page. We\'re here to help with any inquiries, support, or feedback.'}
            />

            <TopBanner
                title={'<h1 class="title-2 fs-65 mb-0">FAQs</h1><p class="f-r fs-20 main-description f-main-medium color-blw">For Olive Branch Dubai</p>'}
            />

            <section id="faq-section" className="faq-section border-bottom">
                <div className="container">
                    <div className="row align-items-center position-relative">
                        <div className="col-12 text-end">
                            <button className="btn btn-faq me-3" onClick={handleExpandAll}>Expand All</button>
                            <button className="btn btn-faq btn-faq-collapse" onClick={handleCollapseAll}>Collapse All</button>
                        </div>
                        <div className="col-12">
                            <Accordion activeKey={activeKey}>
                                {faqs.map((item, index) => {
                                    const key = `faqs-${index}`;
                                    return (
                                        <Accordion.Item eventKey={key} key={key}>
                                            <Accordion.Header onClick={() => handleToggle(key)}>
                                                {item.question}
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p dangerouslySetInnerHTML={{__html: item.answer}}></p>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    );
                                })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Faqs;
