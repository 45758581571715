import React, {Fragment, useState} from "react";
import aboutUs from "../../../../assets/images/about-us.jpg";
import LazyLoad from "react-lazyload";

const Story = () => {
    const [fullView, setFullView] = useState(false);
    return (

        <section  className="position-relative" id="the-story">
            <div className={"container"}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 order-2  mt-lg-5 mt-4">
                        <LazyLoad offset={100}
                                  placeholder={<span
                                      className="spinner-border spinner-border-sm align-middle ms-2 text-dark"></span>}>

                            <img
                                src={aboutUs}
                                alt="About Us"
                                className="w-100"
                            />
                        </LazyLoad>
                    </div>
                    <div className="col-lg-6 col-md-12 order-1  mt-lg-5 mt-4">
                        <div className="mt-lg-0 mt-3">
                            <h2 className={'fs-40 text-black'}>The Story</h2>
                            <div className="dark-light-color fs-16 lh-25 me-lg-5 me-0 pt-3 mb-4">
                                <p>
                                    At Olive Branch Properties, we pride ourselves on our professionalism, expertise,
                                    and commitment to excellence. Our team of highly skilled and experienced real estate
                                    agents is dedicated to ensuring that our clients receive the best possible service,
                                    tailored to their unique needs and preferences.
                                </p>


                                {fullView && (
                                    <Fragment>
                                        <br/>
                                        <p>
                                            We believe that honesty, transparency, and integrity are the foundations of
                                            our business. We always act in the best interests of our clients and ensure
                                            that they receive accurate and up-to-date information about the properties
                                            and the market.
                                        </p>
                                        <br/>
                                        <p>
                                            We are committed to maintaining the highest standards of excellence in
                                            everything we do, and we always go the extra mile to ensure our clients'
                                            complete satisfaction. So whether you’re a first-time buyer or an
                                            experienced investor, trust Olive Branch Properties to help you achieve your
                                            real estate goals in Dubai.

                                        </p>
                                        <br/>
                                        <p>
                                            We understand that buying, selling, or renting a property can be a complex
                                            and challenging process, which is why we strive to make it as simple and
                                            stress-free as possible. We provide our clients with personalized guidance
                                            and support throughout the entire process, from property search to closing
                                            the deal.
                                        </p>
                                        <br/>
                                        <p>
                                            Whether you're looking for a new home, an investment property, or a
                                            commercial space, we have a wide selection of properties to choose from. We
                                            work closely with our clients to understand their goals and priorities, and
                                            then use our extensive knowledge of the Dubai real estate market to help
                                            them find the perfect property.
                                        </p>
                                    </Fragment>
                                )}
                            </div>
                            {!fullView && (
                                <div className="btn btn-black cursor-pointer p-40"
                                    onClick={() => setFullView(true)}>
                                    KNOW MORE
                                </div>

                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Story;
