import axios from 'axios'
import {API_URL} from "../../../helpers/crud-helper/consts";

const PROPERTY_URL = `${API_URL}/property`


const showProperty = (slug: any, page: any, limit: any): Promise<any> => {
    return axios
        .get(`${PROPERTY_URL}/${slug}?page=${page}&limit=${limit}`)
        .then((response) => response)
}

const indexProperties = (page: number, limit: number): Promise<any> => {
    return axios
        .get(`${PROPERTY_URL}?page=${page}&limit=${limit}`)
        .then((response) => response)
}
const propertyRegistration = (value: any): Promise<any> => {
    return axios
        .post(`${PROPERTY_URL}/registration`, value)
        .then((response) => response)
}

export {showProperty, indexProperties, propertyRegistration}
