import React, {FC, useEffect, useState} from "react";
import ResaleCard from "../../../components/Card/ResaleCard";
import {showProperty} from "../core/requests";
import CustomPagination from "../../../components/CustomPagination";

type Props = {
    property?: any
    relatedProperties?: any
    selectedPage?: any
    pagination?: any
}

const RelatedProperties: FC<Props> = ({property, relatedProperties, pagination, selectedPage}) => {
    const [currentPage, setCurrentPage] = useState(selectedPage);
    const [paginationData, setPaginationData] = useState(pagination);
    const [properties, setProperties] = useState<any>(relatedProperties);

    const handlePageChange = (selectedItem: { selected: number }) => {
        setCurrentPage(selectedItem.selected);
        showProperty(property.slug, selectedItem.selected + 1, 3).then(
            (response) => {

                setPaginationData(response.data.resale_leasing_items);
                setProperties(response.data.resale_leasing_items);

            }
        );
    };
    useEffect(() => {
    }, [properties]);

    return (
        <>
            <div className={'row pt-5'}>
                <div className={'col-md-12 text-center'}>
                    <h2 className={'form-title'}>
                        More available property in {property.city}
                    </h2>
                </div>
            </div>
            <div className={'position-relative'}>
                <div className={'row pt-3 pb-3 justify-content-center'}>

                    <div className={'col-lg-10 col-md-12 col-12'}>

                        <div className={'row justify-content-center'}>
                            {properties && properties.data && properties.data.length > 0 && properties.data.map((relatedProperty: any) => (
                                <div className={'col-lg-4 col-md-12 col-12 mt-3 mb-3 text-center'}
                                     key={relatedProperty.id}>
                                    <ResaleCard item={relatedProperty} images={relatedProperty.images}/>
                                </div>
                            ))}
                        </div>
                    </div>


                    <div className="custom-pagination">
                        <CustomPagination pageCount={paginationData.last_page} onPageChange={handlePageChange}
                                          forcePage={currentPage}/>
                    </div>

                </div>
            </div>

        </>


    );
};

export default RelatedProperties;
