// // import React, {Fragment, FC, useEffect, useState} from "react";
// // import { Carousel } from 'react-responsive-carousel';
// // import 'react-responsive-carousel/lib/styles/carousel.min.css';
// // import {toAbsoluteUrl} from "../../../helpers";
// //
// // type Props = {
// //     property ?: any
// // }
// //
// // const ImageCarousel: FC<Props> = ({property}) => {
// //
// //     useEffect(() => {
// //
// //     } , []);
// //
// //
// //     return (
// //         <div className="carousel-container">
// //             <Carousel
// //                 renderArrowPrev={(onClickHandler: () => void, hasPrev: boolean, label: string) =>
// //                     hasPrev && (
// //                         <div
// //
// //                             onClick={onClickHandler}
// //                             title={label}
// //                             className="custom-arrow custom-arrow-prev"
// //                         >
// //                             {/* Custom left arrow image */}
// //                             <img src="/assets/images/icons/arrow-left.png" alt="Prev"/>
// //                         </div>
// //                     )
// //                 }
// //                 renderArrowNext={(onClickHandler: () => void, hasNext: boolean, label: string) =>
// //                     hasNext && (
// //                         <div
// //
// //                             onClick={onClickHandler}
// //                             title={label}
// //                             className="custom-arrow custom-arrow-next"
// //                         >
// //                             {/* Custom right arrow image */}
// //                             <img src="/assets/images/icons/arrow-right.png" alt="Next"/>
// //                         </div>
// //                     )
// //                 }
// //             >
// //                 {property && property.images && property.images.length > 0 && property.images.map((image: any) => (
// //                     <div key={image.id + image.image}>
// //                         <img src={toAbsoluteUrl(image.image)}/>
// //                     </div>
// //                 ))}
// //
// //
// //             </Carousel>
// //         </div>
// //     );
// // };
// //
// // export default ImageCarousel
// import React, {FC, useState} from "react";
// import { Carousel } from 'react-responsive-carousel';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { toAbsoluteUrl } from "../../../helpers";
//
// type Props = {
//     property?: any
// }
//
// const ImageCarousel: FC<Props> = ({ property }) => {
//     const [currentSlide, setCurrentSlide] = useState(0);
//     const handlePrevClick = () => {
//         setCurrentSlide(prevSlide => (prevSlide === 0 ? property.images.length - 1 : prevSlide - 1));
//     };
//
//     const handleNextClick = () => {
//         setCurrentSlide(prevSlide => (prevSlide === property.images.length - 1 ? 0 : prevSlide + 1));
//     };
//
//     return (
//         <div className="carousel-container">
//             {/* Custom left arrow */}
//             <div className="custom-arrow custom-arrow-prev" onClick={handlePrevClick}>
//                 <img src="/assets/images/icons/arrow-left.png" alt="Prev" />
//             </div>
//             {/* Custom right arrow */}
//             <div className="custom-arrow custom-arrow-next"  onClick={handleNextClick}>
//                 <img src="/assets/images/icons/arrow-right.png" alt="Next" />
//             </div>
//             <Carousel
//                 showThumbs={true}
//                 showStatus={false}
//                 selectedItem={currentSlide}
//                 onChange={setCurrentSlide}
//             >
//                 {property && property.images && property.images.length > 0 && property.images.map((image: any) => (
//                     <div key={image.id + image.image}>
//                         <img src={toAbsoluteUrl(image.image)} alt={'Olive Branch'} />
//                     </div>
//                 ))}
//             </Carousel>
//         </div>
//     );
// };
//
// export default ImageCarousel;


import React, {useState} from 'react';
import {toAbsoluteUrl} from "../../../helpers";


interface GalleryProps {
    images: any;
}

const ImageCarousel: React.FC<GalleryProps> = ({images}) => {
    const [loading, setLoading] = useState<boolean[]>(images.map(() => true));
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    };
    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
    };
    const handleImageLoaded = (index: number) => {
        const updatedLoading = [...loading];
        updatedLoading[index] = false;
        setLoading(updatedLoading);
    };
    return (
        <div className="row pt-5">
            <div className={'col-lg-8 pe-lg-1'}>
                <div className={'custom-carousel position-relative'}>
                    <img
                        className="w-100 bdr-2"
                        src={toAbsoluteUrl(images[currentIndex].image)}
                        alt={`Gallery ${currentIndex + 1}`}
                        onLoad={() => handleImageLoaded(currentIndex)}
                    />


                    <button className="custom-arrow custom-arrow-prev" onClick={handlePrev}>
                        <img src="/assets/images/icons/arrow-left.png" alt="Prev"/>
                    </button>
                    <button className="custom-arrow custom-arrow-next" onClick={handleNext}>
                        <img src="/assets/images/icons/arrow-right.png" alt="Next"/>
                    </button>

                    <div className="dots-container d-lg-block d-none">
                        {images.map((_: any, index: number) => (
                            <span
                                key={index}
                                className={`dot ${currentIndex === index ? 'active' : ''}`}
                                onClick={() => handleDotClick(index)}
                            ></span>
                        ))}
                    </div>
                </div>

            </div>
            <div className={'col-lg-4 col-12 d-lg-block d-none ps-lg-1'}>
                <div className={'position-relative'}>
                    {images.length > 1 && (
                        <img
                            className="w-100 bdr-2"
                            src={toAbsoluteUrl(images[(currentIndex + 1) % images.length].image)}
                            alt={`Gallery  ${currentIndex + 2}`}
                            onLoad={() => handleImageLoaded((currentIndex + 1) % images.length)} // Handle image loaded event
                        />
                    )}

                </div>
                <div className={'mt-2'}>
                    <div className={'position-relative'}>
                        {images.length > 2 && (
                            <img
                                className="sidebar-image bdr-2"
                                src={toAbsoluteUrl(images[(currentIndex + 2) % images.length].image)}
                                alt={`Gallery  ${currentIndex + 3}`}
                                onLoad={() => handleImageLoaded((currentIndex + 2) % images.length)} // Handle image loaded event
                            />
                        )}

                    </div>

                </div>

            </div>
        </div>
    );
};

export default ImageCarousel;
