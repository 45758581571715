import React, {FC, useEffect, useState} from "react";
import {Outlet, useLocation} from "react-router-dom";
import "../src/assets/sass/style.scss";
import {useCookies} from 'react-cookie';
import {HeaderProvider} from "./app/layout/context/HeaderContext";
import Header from "./app/layout/Header";
import Footer from "./app/layout/Footer";
import Popup from "./app/components/Popup";



const App: FC = () => {
    // Move useLocation inside BrowserRouter
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);
    const [showPopup, setShowPopup] = useState(false);
    const [cookies, setCookie] = useCookies(['newsletterPopupDisplayed', 'newsletterPopupClosed']);

    useEffect(() => {
        const handleScroll = () => {
            if (!cookies.newsletterPopupDisplayed) {

                if (window.scrollY / document.body.scrollHeight > 0.5 && !cookies.newsletterPopupDisplayed && !cookies.newsletterPopupClosed) {
                    setShowPopup(true);
                    setCookie('newsletterPopupDisplayed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
                }
            }
        };

        if (!cookies.newsletterPopupClosed) {
            const timer = setTimeout(() => {
                setShowPopup(true);
                setCookie('newsletterPopupDisplayed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
            }, 10000); // 10 seconds

            window.addEventListener('scroll', handleScroll);

            return () => {
                clearTimeout(timer);
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [cookies.newsletterPopupDisplayed, cookies.newsletterPopupClosed, setCookie]);


    const handleClosePopup = () => {
        setShowPopup(false);
        setCookie('newsletterPopupClosed', 'true', {path: '/', maxAge: 60 * 60 * 24 });
    };
    return (
        <HeaderProvider>
            <Header/>
            <Outlet/>
            {showPopup && <Popup onClose={handleClosePopup}/>}
            <Footer/>
        </HeaderProvider>
    );
};

export default App;
