import React from "react";
import NewsletterForm from "../components/Form/NewsletterForm";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {scrollToSection} from "../helpers/functions";


const Footer = () => {

    const location = useLocation();
    const navigation = useNavigate();

    const handleNavigation = (id: string) => {
        const path = scrollToSection(id, location);
        if (path) {
            navigation(path);
        }
    };
    const currentYear = new Date().getFullYear();
    return (
        <footer>
            <div className={'container'}>
                <div className="row footerGutter justify-content-center mb-5 ">
                    <div className="col-lg-3 col-md-4 col-6 mt-2">
                        <Link to="/">
                            <img src={'/assets/images/logos/logo-dark.png'} alt="Olive Branch" className="logo w-100"/>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-12 text-lg-start text-center">
                        <ul className="footerLinks">
                            <li>

                                <Link className="nav-link" to="/">
                                    Home
                                </Link>
                            </li>
                            <li>

                                <Nav.Link
                                    className="nav-link"
                                    onClick={() =>
                                        handleNavigation("about-us")
                                    }
                                >
                                    About The Founder
                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link
                                    className="nav-link"
                                    onClick={() =>
                                        handleNavigation("the-story")
                                    }
                                >
                                    The Story
                                </Nav.Link>
                            </li>


                        </ul>
                    </div>
                    <div className="col-lg-2 col-md-12 text-lg-start text-center">
                        <ul className="footerLinks">
                            <li>
                                <Nav.Link
                                    className="nav-link"
                                    onClick={() =>
                                        handleNavigation("roadmap")
                                    }
                                >
                                   Roadmap
                                </Nav.Link>

                            </li>

                            <li>
                                <Link className="nav-link" to={'/blog'}>
                                    Blog

                                </Link>
                            </li>


                        </ul>
                    </div>
                    <div className="col-lg-4 col-md-12 text-lg-start text-center">
                        <NewsletterForm/>
                    </div>
                </div>
            </div>
            <hr className={'bdr-light-grey'}/>
            <div className={'container'}>
                <div className="row">
                    <div className="col-lg-6  text-lg-start text-center">
                        <div className="copySocial justify-content-center  mt-2">
                            <div className="small">
                                Copyright © {currentYear} <br/> Olive Branch Properties All Rights Reserved
                            </div>
                        </div>
                    </div>

                    <div className={'col-lg-6 text-end'}>
                        <div className={'copySocial'}>
                            <ul className="socialLinks mt-2">
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.facebook.com/profile.php?id=100091881626045&mibextid=LQQJ4d"
                                    >
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.instagram.com/olivebranchdubai/"
                                    >
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="https://www.linkedin.com/company/olive-branch-properties"
                                    >
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>

                                    <a href="tel:97148716762"><i className="fa-solid fa-phone"></i></a>
                                </li>
                                <li>

                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href="mailto:
                   info@olivebranchdubai.com "
                                    > <i className="fa fa-envelope"></i>
                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
