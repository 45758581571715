import React from "react";

const WhyChooseUs: React.FC = () => {
    return (
        <div className="bg-about-2 py-80">
            <div className="container-fluid p-0">
                <div className="row align-items-center position-relative">
                    <div className="col-xl-8 col-lg-8 col-lg-8 col-sm-12 col-12 col-centered text-center">
                        <h2 className="fs-40 text-white">Why Choose Us?</h2>
                        <div className="text-white fs-16 lh-25 me-lg-5 me-0 pt-3 mb-4 f-main-medium">
                            <p>
                                Our philosophy is simple: quality over quantity. We believe that a
                                well-trained and motivated team is essential to delivering outstanding
                                service. By fostering a positive and professional environment, we ensure our
                                clients receive the highest level of care and attention.
                            </p>
                        </div>

                        <div className="text-white fs-16 lh-25 me-lg-5 me-0 pt-3 mb-5 f-main-medium">
                            <p>
                                Your Trusted Partner in Dubai Real Estate!
                            </p>
                        </div>

                        <div className="w-100 w-w-sm-80">
                            <img src="/assets/images/about-why.jpg?v=123" className="w-100 br-8px"
                                 alt="Why Choose Us?"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
