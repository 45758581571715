import React, {FC, useState} from "react";
import {showProjectMapping} from "../../../core/home/requests";
import {toAbsoluteUrl} from "../../../helpers";
import Select from "react-select";
import clsx from "clsx";

type Props = {
    projects?: any
    projectFirst?: any
}
const Offplan: FC<Props> = ({projects, projectFirst}) => {
    const [currentProjectMapping, setCurrentProjectMapping] = useState<any>(projectFirst)
    const [parent, setParent] = useState<any>(projects[0]);
    const defaultProject = {label: projects[0].name, value: projects[0].slug};
    const [currentPage, setCurrentPage] = useState<number>(1);
    const imagesPerPage = 1; // Adjust this based on how many images per page you want to display
    const [selectedMapping, setSelectedMapping] = useState({
        label: projects[0].project_mapping[0].name,
        value: projects[0].project_mapping[0].slug
    });

    const handleClick = (value: any, name: any) => {
        setCurrentPage(1);
        setSelectedMapping({
            label: name,
            value: value
        });

        showProjectMapping(value).then((response) => {
            setCurrentProjectMapping(response.data.project_mapping)
        });
    }
    const handleProjectClick = (selectedOption: any) => {
        if (selectedOption) {
            const selectedProject = projects.find((project: any) => project.slug === selectedOption.value);
            setParent(selectedProject);
            handleClick(selectedProject.project_mapping[0].slug, selectedProject.project_mapping[0].name)
            setCurrentPage(1);

        }
    }
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = currentProjectMapping.images.slice(
        indexOfFirstImage,
        indexOfLastImage
    );

    // Change page
    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);



    return (
        <section className={'offplan bg-white mb-5 pb-5  position-relative'}>
            <div className={'container z-index-999 '}>
                <div className={'z-index-999 position-relative'}>
                    <div className="row">
                        <div className="col-lg-12 text-center mt-5 mb-4">
                            <h3 className={'title-header text-main mt-2 pb-2'}>Offplan</h3>
                        </div>
                    </div>
                    <div className={'bg-green-secondary bdr-t-6 ps-3 pe-3 '}>
                        <div className="row justify-content-center pb-3 align-items-center pt-4 ">
                            <div className="col-lg-3 ">
                                <div className={'title-main text-white mb-lg-0 mb-2'}>
                                    Search for your property:
                                </div>
                            </div>
                            <div className={'col-lg-4 mb-1'}>
                                <div>

                                    <Select
                                        className={clsx(
                                            "text-dark w-100",
                                        )}
                                        closeMenuOnSelect={true}
                                        placeholder={"Select The Location"}
                                        options={projects.map((e: {
                                            name: any;
                                            slug: any
                                        }) => ({label: e.name, value: e.slug}))}
                                        isSearchable={true}
                                        onChange={(e) => handleProjectClick(e)}
                                        defaultValue={defaultProject}


                                    />
                                </div>
                            </div>

                            {parent && parent.project_mapping.length > 0 &&
                                <div className={'col-lg-4'}>
                                    <div>

                                        <Select
                                            className={clsx(
                                                "text-dark w-100",
                                            )}
                                            closeMenuOnSelect={true}
                                            placeholder={"Select The Location"}
                                            options={parent.project_mapping.map((e: {
                                                name: any;
                                                slug: any
                                            }) => ({label: e.name, value: e.slug}))}
                                            isSearchable={true}
                                            onChange={(newValue: any) =>
                                                handleClick(newValue.value, newValue.label)
                                            }

                                            value={selectedMapping}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <hr className={'separator-2'}/>

                        <div className="row justify-content-center pb-3 align-items-center pt-4">
                            <div className={'col-md-11'}>
                                <h4 className={'title-header text-start text-white'}>
                                    {parent.name}:
                                </h4>
                                <p className={'offplan-description-main'}>
                                    {parent.description}
                                </p>
                            </div>

                            <div className="col-md-11">
                                <div className={'bg-white bdr-t-6 mt-5 mb-5'}>
                                    <div className={'text-center p-lg-5 p-3'}>
                                        <h4 className={'title-main fs-34 pb-lg-3 p-1'}>{currentProjectMapping.name}</h4>
                                        <p className={'description-2'}>{currentProjectMapping.description}</p>
                                        {currentImages &&
                                            <div className={"mt-4"}>
                                                <img
                                                    src={toAbsoluteUrl(
                                                        currentImages[0].image // Assuming you want to display only the first image of the current page
                                                    )}
                                                    className={"w-100 h-400px object-fit-contain"}
                                                    alt={currentProjectMapping.name}
                                                />
                                            </div>
                                        }
                                    </div>
                                    <div className={'text-center pb-5'}>
                                        <ul className={" pagination justify-content-center flex-wrap"}>
                                            <li className={"page-item"}>
                                                <button
                                                    onClick={() => paginate(currentPage - 1)}
                                                    className={clsx("page-link arrow-prev", {
                                                        disabled: currentPage === 1,
                                                    })}
                                                    disabled={currentPage === 1}
                                                >
                                                    <img src={'/assets/images/icons/previous.png'}
                                                         alt={'Olive Branch'}/>
                                                </button>
                                            </li>
                                            {currentProjectMapping.images.map((image: any, index: number) => (
                                                <li key={index} className={"page-item"}>
                                                    <button
                                                        onClick={() => paginate(index + 1)}
                                                        // Add active class conditionally based on currentPage
                                                        className={clsx("page-link", {
                                                            active: index + 1 === currentPage,
                                                        })}
                                                    >
                                                        {index + 1}
                                                    </button>
                                                </li>
                                            ))}

                                            <li className={"page-item"}>
                                                <button
                                                    onClick={() => paginate(currentPage + 1)}
                                                    className={clsx("page-link arrow-next", {
                                                        disabled:
                                                            indexOfLastImage >= currentProjectMapping.images.length,
                                                    })}
                                                    disabled={
                                                        indexOfLastImage >= currentProjectMapping.images.length
                                                    }
                                                >
                                                    <img src={'/assets/images/icons/next.png'} alt={'Olive Branch'}/>
                                                </button>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <img src={'/assets/images/about-overlay.png'} alt="Olive Branch"
                 className="overlay-right d-lg-block d-none"/>

        </section>
    );
}

export default Offplan;

