import React, {FC} from "react";
import {Link} from "react-router-dom";
import {toAbsoluteUrl} from "../../../helpers";
import {PROPERTY_STATUS} from "../../../helpers/crud-helper/consts";

type Props = {
    property?: any
}

const Details: FC<Props> = ({property}) => {

    return (
        <div className={'bg-white mt-3 p-4 bdr-2 '}>
            <div className={'d-flex justify-content-between'}>
                <h4 className={'property-type'}>
                    {property.property}
                </h4>
                {property.availability && property.availability !== "2" &&
                    <div className={'project-status'}>{PROPERTY_STATUS[property.availability]}</div>
                }
            </div>

            <h1 className={'property-name mt-2'}>
                {property.name}
            </h1>
            <div className={'row  mt-3'}>
                <div className={'col-lg-4 property-price mt-1'}>
                    {property.price} {property.currency}
                    <div className={'select-price d-flex'}>
                        <div className={'select-price-label'}>
                            Payout Currency
                        </div>
                        <div className={'select-price-label ms-4'}>
                            AED
                            {/*<Dropdown options={options} onChange={handleDropdownChange}/>*/}
                        </div>
                    </div>
                </div>
                {property.beds &&
                    <div className={'col-lg-2 mt-1'}>
                        <div className={'property-nb'}>
                            <img src={'/assets/images/icons/bed.png'} className={'icon-s'}
                                 alt={'Olive Branch Icon'}/> {property.beds}
                        </div>
                        <div className={'main-text mt-2 sm'}>
                            Bedrooms
                        </div>
                    </div>
                }

                {property.bathrooms &&
                    <div className={'col-lg-2 mt-1'}>
                        <div className={'property-nb'}>
                            <img src={'/assets/images/icons/bathroom.png'} className={'icon-s me-2'}
                                 alt={'Olive Branch Icon'}/> {property.bathrooms}
                        </div>
                        <div className={'main-text mt-2 sm'}>
                            Baths
                        </div>
                    </div>
                }

                {property.size &&
                    <div className={'col-lg-4 property-nb mt-1'}>
                        <div>
                            <img src={'/assets/images/icons/size.png'} className={'icon-s me-2'}
                                 alt={'Olive Branch Icon'}/> {property.size}
                        </div>
                        <div className={'main-text mt-2 sm'}>
                            Square (ft)
                        </div>
                    </div>
                }

            </div>
            <div className={'property-location-block mt-4 mb-4'}>
                <div className={'d-flex'}>
                    <div><img className={'icon-s me-2'}
                              src={toAbsoluteUrl('/assets/images/icons/location.svg')} alt={'Olive Branch Icon'}/></div>
                    <div
                        className={'ms-2 mt-1 property-location'}>{property.location}</div>
                </div>
            </div>

            {property.price_per_square_foot &&
                <div className={'select-price-label mt-3 mb-5'}>
                    {property.price_per_square_foot} {property.currency} per ft²
                </div>
            }

            <hr className={'bdr-light-grey'}/>


            {property.description &&
                <div className={'row justify-content-center'}>
                    <div className={'col-md-12 mt-4'}>
                        <h6 className={'secondary-title'}>Property Details:</h6>
                    </div>
                    <div className={'col-md-12 mt-2'}>
                        <div className={'bg-light-2'}>
                            <div className={'main-text p-4 light'}
                                 dangerouslySetInnerHTML={{__html: property.description}}/>


                        </div>
                    </div>
                </div>
            }

            {property.features &&
                <div className={'row'}>
                    <div className={'col-md-12 mt-4'}>
                        <h6 className={'info-title'}>Features and Amenities:</h6>
                    </div>
                    <div className={'col-md-10 mt-2 '}>
                        <div className="main-text light" dangerouslySetInnerHTML={{__html: property.features}}/>

                    </div>
                </div>
            }

            {(property.unit || property.property_name || property.emirate || property.status || property.purpose || property.date) &&
                <div className={'row'}>
                    <div className={'col-md-12 mt-4'}>
                        <h6 className={'dark-title'}>Project Details:</h6>
                    </div>
                    <div className={'col-md-12 col-12 mt-1 '}>
                        <div className={'table-container bg-light-2'}>
                            <table className="custom-table w-100 mb-4">
                                <tbody className={''}>

                                <tr>
                                    {property.unit &&
                                        <>
                                            <td className={'main-text bolder  ps-3 pt-1'}>Unit Reference</td>
                                            <td className={'main-text'}>{property.unit}</td>
                                        </>

                                    }

                                    {property.status &&
                                        <>
                                            <td className={'main-text bolder ps-3 pt-1'}>Status</td>
                                            <td className={'main-text pt-2'}>{property.status}</td>
                                        </>
                                    }
                                </tr>
                                <tr>
                                    {property.property_name &&
                                        <>
                                            <td className={'main-text bolder ps-3 pt-1'}>Property Name</td>
                                            <td className={'main-text pt-2'}>{property.property_name}</td>
                                        </>
                                    }
                                    {property.purpose &&
                                        <>
                                            <td className={'main-text bolder ps-3 pt-1'}>Purpose</td>
                                            <td className={'main-text pt-2'}>For {property.purpose} </td>
                                        </>
                                    }
                                </tr>


                                {property.emirate &&
                                    <tr className={''}>
                                        <td className={'main-text bolder ps-3 pt-1'}>Emirate</td>
                                        <td className={'main-text pt-2'}>{property.emirate}</td>
                                    </tr>
                                }
                                {property.date &&
                                    <tr className={''}>
                                        <td className={'main-text bolder ps-3 pt-1'}>Added On</td>
                                        <td className={'main-text pt-2'}>{property.date}</td>
                                    </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }

            {(property.qr_code || property.permit_link || property.permit) &&
                <div className={'row mt-4'}>

                    {property.qr_code && (
                        <div className={'col-lg-4 col-10 mt-2 mb-4'}>
                            <h6 className={'pl-f'}>DLD Permit</h6>
                            <div className={'mt-2'}>
                                <Link to={property.permit_link}
                                      target={'_blank'}>
                                    <img src={toAbsoluteUrl(property.qr_code)} alt={'Qr Code'}
                                         className={'w-100'}/>
                                </Link>
                                <div>
                                    <div className={'main-text text-center'}>
                                        QR Code
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {property.permit &&

                        <div className={'col-md-5 mt-2 mb-4'}>
                            <div>
                                <div className={'pl-f'}>
                                    DLD Permit Number:
                                </div>
                            </div>
                            <div className={'dld-nb mt-3'}>
                                {property.permit}
                            </div>
                        </div>
                    }
                    {property.permit_link &&
                        <div className={'col-md-3 mt-2'}>
                            <Link
                                to={property.permit_link}
                                className={'btn btn-white pt-3 pb-3 pe-2 ps-1 w-100'} target={'_blank'}>
                                View Permit
                            </Link>
                        </div>
                    }
                </div>
            }


        </div>
    );
};

export default Details
