import React from "react";

const Philosophy: React.FC = () => {
    return (
        <div className="bg-about py-80">
            <div className="pattern position-absolute left-0 w-150px">
                <img className="w-100"
                     src={'/assets/images/patterns/pattern-2.svg?v=123'} alt={'Olive Branch Pattern'}/>
            </div>

            <div className="container">
                <div className="row align-items-center position-relative">
                    <div className="col-xl-10 col-lg-10 col-lg-8 col-sm-12 col-12 col-centered">
                        <div className="row d-flex align-items-center">
                            <div className="col-xl-5 col-lg-5 col-md-5 col-12 w-mb-sm-25px">
                                <h2 className="fs-40 text-black">Our Philosophy</h2>
                                <div className="dark-light-color fs-16 lh-25 me-lg-5 me-0 pt-3 mb-4 f-main-medium">
                                    <p>
                                        Our philosophy is simple: quality over quantity. We believe that a
                                        well-trained and motivated team is essential to delivering outstanding
                                        service. By fostering a positive and professional environment, we ensure our
                                        clients receive the highest level of care and attention.
                                    </p>
                                </div>
                            </div>
                            <div className="col-2 p-0"></div>
                            <div className="col-xl-5 col-lg-5 col-md-5 col-12 d-flex justify-content-center">
                                <div className="w-100">
                                    <img src="/assets/images/about-philosophy.png?v=123" className="w-100"
                                         alt="Our Mission"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Philosophy;
