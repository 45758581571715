import React, {FC} from "react";
import BlogCard from "./BlogCard";
import {Link} from "react-router-dom";


type Props = {
    blog?: any
    selectedPage?: any
    pagination?: any
}

const Blog: FC<Props> = ({blog}) => {


    return (
        <>
            <div className={'bg-primary-light position-relative mt-5'}>
                <div className={'container pt-3 pb-5'}>
                    <div className={'row mt-5 justify-content-center'}>
                        <div className={'col-md-12 text-center'}>
                            <h2 className={"fs-40 text-black pb-3 "}>Blog</h2>
                        </div>
                    </div>
                    {blog && blog.data && blog.data.length > 0 &&
                        <div className={'row mt-4 justify-content-center pb-4'}>

                            {blog && blog.data.length >= 1 &&
                                <BlogCard article={blog.data[0]} btnClass={'btn btn-primary lg'} dateClass={'date'}
                                          bigCard={true}
                                          titleClass={'title-smm'} colClass={'col-xl-8 col-lg-12 col-12 mb-3'}
                                          withDescription={true}
                                          imageHeight={'h-450px'} heightBlock={'h-280px'}/>
                            }
                            <div className={'col-xl-4 col-lg-12 col-md-12'}>
                                <div className={'row justify-content-center'}>
                                    {blog && blog.data.length >= 2 &&
                                        <BlogCard article={blog.data[1]} btnClass={'btn btn-primary-inverse'}
                                                  dateClass={'date sm'} titleClass={'title-sm'}
                                                  colClass={'col-xl-12  col-lg-12 col-12 mb-3'}

                                                  withDescription={false} imageHeight={'h-200px'} paddingClass={'sm'}
                                                  heightBlock={'h-160px'}/>
                                    }

                                    {blog && blog.data.length >= 3 &&
                                        <BlogCard article={blog.data[2]} btnClass={'btn btn-primary-inverse'}
                                                  dateClass={'date sm'} titleClass={'title-sm'}
                                                  colClass={'col-xl-12 col-lg-12 col-12'} withDescription={false}
                                                  imageHeight={'h-200px'} paddingClass={'sm'} heightBlock={'h-160px'}/>

                                    }
                                </div>
                            </div>
                        </div>
                    }

                    <div className={'row justify-content-center'}>
                        <div className={'col-lg-3'}>
                            <Link className={'btn btn-primary lg w-100'} to={'/blog'}>More Articles</Link>
                        </div>
                    </div>


                </div>
            </div>
        </>
    );
};

export default Blog;
