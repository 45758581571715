// Header.tsx
import React, {useState} from "react";
import {Container, Nav, Navbar} from "react-bootstrap";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {useHeaderContext} from "./context/HeaderContext";
import {scrollToSection} from "../helpers/functions";


const Header: React.FC = () => {
    const {variant, logoColor} = useHeaderContext();
    const location = useLocation();
    const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarExpanded(!isNavbarExpanded);
    };
    const navigation = useNavigate();
    const handleNavigation = (id: string) => {
        const path = scrollToSection(id, location);
        if (path) {
            navigation(path);
        }
    };

    return (
        <header className={`container-fluid`}>
            <Navbar collapseOnSelect expand="lg" className={` ${variant === "property" ? "property-header" : ""}`}>
                <Container className="navContainer">
                    <Navbar.Brand>
                        <Link to="/">
                            <img
                                src={`/assets/images/logos/logo-${logoColor}.png?v=1`}
                                width="300"
                                alt="Olive Branch"
                                className="logo"
                            />
                        </Link>
                    </Navbar.Brand>

                    <div className="w-100 text-center pb-2 z-index-999">
                        <Navbar.Toggle className={`text-${logoColor}`} onClick={toggleNavbar} aria-controls="navbarScroll">
                            {isNavbarExpanded ? (<i className="fa-solid fa-xmark" />) : (
                                <i className="fa-solid fa-bars" />)}
                        </Navbar.Toggle>
                        <Navbar.Collapse className={`bg-${variant === "listing" ? "light" : "sm-dark"}`}
                                         id="responsive-navbar-nav">
                            <Nav className="me-auto align-items-center">
                                <Link
                                    to="/"
                                    className={`nav-link ${location.pathname === "/" ? "active" : ""}`}
                                >
                                    Home
                                </Link>
                                <Nav.Link
                                    className="nav-link"
                                    onClick={() =>
                                        handleNavigation("about-us")
                                    }
                                >
                                    About The Founder
                                </Nav.Link>
                                <Nav.Link
                                    className="nav-link"
                                    onClick={() => handleNavigation("the-story")}
                                >
                                    The Story
                                </Nav.Link>

                                <Nav.Link
                                    onClick={() => handleNavigation("roadmap")}
                                    className={`nav-link`}
                                >
                                    Roadmap
                                </Nav.Link>
                                <Link
                                    className={`nav-link ${location.pathname.includes("/proper") ? "active" : ""}`}
                                    to="/properties/dubai-creek-harbour"

                                >
                                    Properties
                                </Link>
                                <Link
                                    className={`nav-link ${location.pathname.includes("/blog") ? "active" : ""}`}
                                    to="/blog"

                                >
                                    Blog
                                </Link>
                                <Link
                                    className={`nav-link ${location.pathname.includes("/faqs") ? "active" : ""}`}
                                    to="/faqs"

                                >
                                    FAQs
                                </Link>
                                <Link
                                    className={`nav-link btn-bordered-white 2 ${location.pathname.includes("/list-your-property") ? "active" : ""}`}
                                    to="/list-your-property"

                                >List Your Property
                                </Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
