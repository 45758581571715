import React, {FC} from "react";

const Page404: FC = () => {
    return (
        <div className="page-404">
            <h1 className="text-center text-danger">404</h1>
        </div>
    );
};

export default Page404;
