import React from 'react';


type Props = {
    location: string;
};

const GoogleMapComponent: React.FC<Props> = ({location}) => {


    return (
        <div>
            <div className={'iframe-container'}>
                <div dangerouslySetInnerHTML={{__html: location}}/>
            </div>
        </div>
    );
};

export default GoogleMapComponent;



