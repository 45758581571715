
export const scrollToSection = (id: string, location: any) => {

    const section = document.getElementById(id);
    if (location.pathname === "/") {
        if (section) {
            window.scrollTo({
                top: section.offsetTop - 20, // Adjust offset as needed
                behavior: "smooth",
            });
        }
    } else {
        return "/";
    }

};