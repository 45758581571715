import React from "react";


const CallCard = () => {

    return (
        <div className={'mt-3'}>
            <div className={'bg-white'}>
                <div className={'bg-main p-3'}>
                    <h2 className={'contact-title text-center text-white '}>
                        Let's have a conversation!
                    </h2>
                    {/*<div className={'contact-sm'}>*/}
                    {/*    Call or Chat here with us:*/}
                    {/*</div>*/}
                </div>
                <div>
                    <div className={'row justify-content-center align-items-center mt-3'}>
                        <div className={'col-lg-2 col-3'}>
                            <img className={'w-100'}
                                 src={'/assets/images/icons/call-icon.svg'} alt={'Olive Branch Icon'}/>
                        </div>
                        <div className={'col-lg-6 col-8'}>
                            <p className={'dark-note'}>Call or Chat here with us:</p>
                        </div>

                    </div>
                    <div className={'row justify-content-center mt-3 pb-5'}>

                        <div className={'col-lg-8 col-11 mt-2'}>
                            <div>

                                <a className={'btn btn-white sm call w-100'}
                                   href={`tel:${'971507173741'}`}> <img
                                    className={'me-2 icon-sm '} alt={'Olive Branch Icon'}
                                    src={'/assets/images/icons/phone-ob.png'}/> Call
                                                                                now</a>


                            </div>
                            <div>

                                <a className={'btn btn-white mt-2 w-100'}
                                   href={`whatsapp://send?phone=${'971507173741'}&text=Hello, I would like to get more information about the properties `}>
                                    <img className={'me-2 icon-sm'} alt={'Olive Branch Icon'}
                                         src={'/assets/images/icons/whtsapp-ob.svg'}/> whatsapp
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default CallCard;
