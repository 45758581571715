import React, {useEffect, useState} from "react";
import ImageCarousel from "./components/ImagesCarousel";
import {useParams} from "react-router-dom";
import {showProperty} from "./core/requests";
import Details from "./components/Details";
import GoogleMapComponent from "./components/GoogleMapComponent";
import RelatedProperties from "./components/RelatedProperties";
import {SocialMeta} from "../../components/SocialMeta";
import RequestConsultationForm from "./components/Form";
import CallCard from "../../components/Card/CallCard";
import {useHeaderContext} from "../../layout/context/HeaderContext";


const PropertyDetails = () => {
    const params = useParams()
    const [property, setProperty] = useState<any>();
    const [relatedProperties, setRelatedProperties] = useState<any>();
    const [selectedPage, setSelectedPage] = useState<number>(0);
    const [paginationData, setPaginationData] = useState({
        last_page: 0,
        current_page: 0,
        total: 0,
    });
    const {setVariant, setLogoColor} = useHeaderContext();
    useEffect(() => {
        // Set variant to "default" when component mounts (assuming homepage needs transparent background)
        setVariant("property");
        setLogoColor("dark")
        return () => {
            // Clean up or reset when component unmounts
            setVariant("property");
            setLogoColor("dark");
        };
    }, [setVariant, setLogoColor]);
    useEffect(() => {
        showProperty(params.slug, 1, 3).then(response => {
            setProperty(response.data.property)
            setRelatedProperties(response.data.resale_leasing_items)
            setPaginationData(response.data.resale_leasing_items);
            setSelectedPage(0);
        })

    }, [params.slug]);


    return (
        <>
            <SocialMeta
                title={property && property.meta_title ? property.meta_title : 'Olive Branch Properties: Real Estate Specialists in Dubai'}
                description={property && property.meta_description ? property.meta_description : 'Find your dream property in Dubai! Explore luxury apartments, villas, & off-plan projects. Expert agents. Contact Olive Branch Properties today!'}/>

            <div>
                {property &&
                    <div className={'property bg-property-page'}>
                        <div className={'container'}>
                            {property && <ImageCarousel images={property.images}/>}

                            <div className="row">
                                <div className={'col-lg-8  pe-lg-1'}>
                                    <Details property={property}/>


                                    {property.google_location_iframe &&
                                        <div className={'row mb-2'}>
                                            <div className={'col-md-12 mt-4'}>
                                                <h6 className={'dark-title'}>Explore the Area:</h6>
                                            </div>
                                            <div className={'property-location-block mt-2 mb-2'}>
                                                <div className={'d-flex'}>
                                                    <div><img width={'15'} height={'15'}
                                                              src={'/assets/images/icons/location.svg'}
                                                              alt={'olive Branch'}/></div>
                                                    <div
                                                        className={'ms-2 mt-1 property-location'}>{property.location}</div>
                                                </div>
                                            </div>

                                            <GoogleMapComponent location={property.google_location_iframe}/>

                                        </div>
                                    }
                                </div>


                                <div className={'col-lg-4 col-md-12 mt-3'}>
                                    <RequestConsultationForm property={property}/>
                                    <CallCard/>

                                </div>
                            </div>
                            {relatedProperties && relatedProperties.data && relatedProperties.data.length > 0 &&
                                <RelatedProperties property={property} relatedProperties={relatedProperties}
                                                   selectedPage={selectedPage} pagination={paginationData}/>
                            }
                        </div>
                    </div>
                }


            </div>

        </>
    );
};

export default PropertyDetails;
