// Popup.tsx
import React, {useState} from 'react';
import * as Yup from "yup";
import {useFormik} from "formik";
import {subscribe} from "../core/newsletter/requests";
import clsx from "clsx";

interface PopupProps {
    onClose: () => void;
}

const Popup: React.FC<PopupProps> = ({onClose}) => {
    const initialValues = {
        email: "",
    };
    const newsletterSchema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format")
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required("Email is required"),
    });

    const [loading, setLoading] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const formik = useFormik({
        initialValues,
        validationSchema: newsletterSchema,
        onSubmit: async (values, {setSubmitting}) => {
            setLoading(true);

            setSubmitting(true);
            try {

                //
            await subscribe(values);

                setLoading(false);
                setIsActive(true);
                formik.resetForm();
                setTimeout(function () {
                    setIsActive(false);
                }, 3500);

            } catch (ex) {
                console.error(ex);
            } finally {
                setSubmitting(true);
                setLoading(false);
            }
        },
    });
    return (
        <div className="popup z-index-999">
            <div className="popup-content position-relative">
                <span className="close" onClick={onClose}>&times;</span>
                <div className={'row justify-content-center align-items-lg-start align-items-center'}>
                    <div className={'col-lg-7 col-md-9 col-8 pe-lg-2 pe-0 mb-lg-0 mb-3'}>
                        <h4>Unlock exclusive Dubai real estate insights & opportunities</h4>
                        <p className={'mt-lg-3 mt-1 me-lg-4 me-1 mb-lg-4 mb-3'}>Subscribe to our newsletter for expert analysis, the latest Dubai
                                                   property
                                                   listings, and
                                                   valuable content for both buyers and investors.</p>

                    </div>
                    <div className={'col-lg-5 col-md-3 col-4 mb-lg-0 mb-3'}>
                        <div>

                            <img src={'/assets/images/list-property/newsletter.png'}
                                 className={'w-100 bdr-7-md newsletter-img'}
                                 alt={'Olive Branch'}/>

                            {/*<img src={'/assets/images/list-property/newsletter.png'}*/}
                            {/*     className={'w-100 d-lg-none d-md-block object-fit-contain bdr-7'}*/}
                            {/*     alt={'Olive Branch'}/>*/}
                        </div>
                    </div>
                </div>

                <form className="z-index-999"
                      onSubmit={formik.handleSubmit}
                      noValidate>
                {isActive ?
                        <div className={`subscribe-form   alert-box d-block`}>
                            <div className="alert alert-success alert-dismissible fade show p-2 mt-2" role="alert">
                                Successfully Subscribed!
                            </div>
                        </div>
                        :
                        <div className="input-group subscribe-form">


                            <input type="email" placeholder="Email Address"    {...formik.getFieldProps("email")}
                                   className={clsx(
                                       "form-control subscribe-input",
                                       {
                                           "is-invalid":
                                               formik.touched.email && formik.errors.email,
                                       },
                                       {
                                           "is-valid":
                                               formik.touched.email && !formik.errors.email,
                                       })}/>
                            <button disabled={formik.isSubmitting || !formik.isValid}
                                    className="btn btn-primary">{!loading && (
                                <span className="indicator-label">
                                  Subscribe
                                </span>
                            )}
                                {loading && (
                                    <span
                                        className="indicator-progress"
                                        style={{display: "block"}}
                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                )}
                            </button>
                        </div>
                    }

                </form>


            </div>
        </div>
    );
};

export default Popup;
