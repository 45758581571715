import React, {FC} from "react";
import LazyLoad from "react-lazyload";
import {useLocation, useNavigate} from "react-router-dom";
import {scrollToSection} from "../../../helpers/functions";

type Props = {
    title?: string;
    showBtn?: boolean;
    video?: string;
};

const TopBanner: FC<Props> = ({title, showBtn, video}) => {
    const navigation = useNavigate();
    const location = useLocation();
    const handleNavigation = (id: string) => {
        const path = scrollToSection(id, location);
        if (path) {
            navigation(path);
        }
    };
    return (
        <div className="container-fluid px-0 top-banner overflow-hidden">
            {video ? (
                <div className="video-wrapper">
                    <video autoPlay muted loop playsInline className="video w-100" poster={'/assets/poster.jpg'}>
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
            ) : (
                <LazyLoad>
                    <div className={'banner-image w-100'}/>
                </LazyLoad>
            )}

            <div className={'block-center'}>
                <div className={'pt-5 text-center'} dangerouslySetInnerHTML={{__html: title || ""}}/>
                {showBtn && (
                    <div className={'text-center'}>
                        <div className="btn btn-black cursor-pointer"

                             onClick={() =>
                                 handleNavigation("resale")
                             }>
                            SEARCH FOR PROPERTIES
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default TopBanner;
