import React, {FC, useEffect, useState} from "react";
import {showCity} from "../../../core/home/requests";
import Select from "react-select";
import clsx from "clsx";
import ResaleCard from "app/components/Card/ResaleCard";
import TopBanner from "../../home/components/TopBanner";

type Props = {
    cities?: any
    cityFirst?: any
    selectedPage?: any
    pagination?: any
}
const Resale: FC<Props> = ({cities, cityFirst, pagination, selectedPage}) => {
    const [activeCity, setActiveCity] = useState(cityFirst)
    const [loading, setLoading] = useState(false)
    const [loadingMoreProperties, setLoadingMoreProperties] = useState(false)
    const [paginationData, setPaginationData] = useState(pagination);
    const [currentPage, setCurrentPage] = useState(1);
    const defaultCity = {label: activeCity.name, value: activeCity.slug};

    const handleTabChange = (eventKey: any) => {
        setLoading(true)
        showCity(eventKey, 1, 9).then((response) => {
            if (eventKey === activeCity.slug) {
                return;
            }
            setPaginationData(response.data.city.resale_leasing.meta);
            setActiveCity(response.data.city)
            setLoading(false)
            setCurrentPage(1)
        });
    };
    const loadMoreProperties = async () => {
        const newPage = currentPage + 1;
        setCurrentPage(currentPage + 1)
        setLoadingMoreProperties(true)

        try {
            const response = await showCity(activeCity.slug, newPage, 9);

            setPaginationData(response.data.city.resale_leasing.meta);

            setActiveCity((prevState: any) => {
                const prevProperties = prevState.resale_leasing.data || [];
                const newProperties = response.data.city.resale_leasing.data || [];

                return {
                    ...prevState,
                    resale_leasing: {
                        ...prevState.resale_leasing,
                        data: [...prevProperties, ...newProperties],
                        meta: response.data.city.resale_leasing.meta
                    }
                };
            });
            // console.log(activeCity)


        } catch (error) {
            console.error("Error loading more properties:", error);
        } finally {
            setLoadingMoreProperties(false);
        }
    };


    useEffect(() => {
    }, [activeCity, paginationData, currentPage]);
    return (
        <section className={'resale bg-property-page position-relative'}>

            <TopBanner title={'<h1 class="title-2 fs-65">Properties</h1>'}/>
            <div className={'container pb-5 pt-5'}>

                <div className={'bg-white bdr-t-10 ps-3 pe-3 '}>
                        <div className="row justify-content-center pb-4 align-items-center pt-4">
                            <div className="col-lg-3 ">
                                <div className={'title-main'}>
                                    Search for your property:
                                </div>
                            </div>
                            <div className={'col-lg-6 mt-2'}>
                                <div>

                                    <Select
                                        className={clsx(
                                            "text-dark w-100",
                                        )}
                                        closeMenuOnSelect={true}
                                        placeholder={"Select The Location"}
                                        options={cities.map((e: {
                                            name: any;
                                            slug: any
                                        }) => ({label: e.name, value: e.slug}))}
                                        isSearchable={true}
                                        onChange={(newValue: any) =>
                                            handleTabChange(newValue.value)
                                        }
                                        defaultValue={defaultCity}


                                    />
                                </div>
                            </div>


                        </div>

                    </div>

                <div className={'bg-secondary-light bdr-b-10 position-relative'}>
                        <div className="row justify-content-center">
                            <div className="col-lg-10 text-center pt-4">
                                <h4 className={'title-main'}>{activeCity.name}</h4>
                                <p className={'main-description  ps-2 pe-2'}>{activeCity.description}</p>


                            </div>

                            <div className="col-lg-11 mt-1">
                                <hr className={'separator'}/>
                            </div>

                            <div className="row justify-content-center mt-5 ps-lg-4 pe-lg-4">

                                {loading && (
                                    <div className="col-md-11 text-center mb-5">
                                                <span
                                                    className="indicator-progress"
                                                    style={{display: "block"}}
                                                >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span></div>
                                )}
                                {!loading && activeCity && activeCity.resale_leasing && activeCity.resale_leasing.data && activeCity.resale_leasing.data.length > 0 && activeCity.resale_leasing.data.map((item: any) => (

                                    <div className="col-lg-4 col-md-12 mb-3"

                                         key={item.id}>

                                        <ResaleCard item={item}/>

                                    </div>
                                ))}
                            </div>

                        </div>
                        {activeCity.resale_leasing.data.length < paginationData.total &&
                            <div className="row justify-content-center pb-5">
                                <div className="col-lg-3">

                                    {!loadingMoreProperties && (
                                        <button onClick={loadMoreProperties} className="btn btn-primary lg w-100">

                                            Load more

                                        </button>
                                    )}
                                    {loadingMoreProperties && (
                                        <button className="btn btn-primary lg w-100">
                                                    <span
                                                        className="indicator-progress"
                                                        style={{display: "block"}}
                                                    >
                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        }

                    </div>



            </div>
        </section>
    );
};

export default Resale;
